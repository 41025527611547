import { patchState, signalStoreFeature, type, withMethods } from "@ngrx/signals";
import { EntityId, setAllEntities } from "@ngrx/signals/entities";
import { inject, Signal } from "@angular/core";
import { Widget, WidgetsData } from "./widgets.types";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { exhaustMap, switchMap, take, tap } from "rxjs";
import { EnvironmentStore } from "../environment/environment.store";
import { filter } from "rxjs/operators";
import { ApiService } from "../../api/http-services/api.service";
import { toObservable } from "@angular/core/rxjs-interop";
import { HelperFunctions } from "../../helpers/HelperFunctions";

export function withWidgetsApi<E extends Widget>() {
    return signalStoreFeature(
        {
            state: type<{
                entityMap: Record<EntityId, E>,
                ids: EntityId[],
            }>(),
            signals: type<{
                entities: Signal<Widget[]>
            }>(),
            methods: type<{} >()
        },
        withMethods((store: any, environmentStore = inject(EnvironmentStore), apiService = inject(ApiService)) => ({
            loadWidgetData: rxMethod<void>(
                exhaustMap(() => {
                    return toObservable(environmentStore.amo.domain).pipe(
                        filter((domain: string) => !!domain),
                        take(1),
                        switchMap((domain: string) => {
                            return apiService.getWidgetData(domain, 'ru').pipe(
                                tap({
                                    next: (widgetsData: WidgetsData) => {
                                        widgetsData.list.forEach((widget: Widget) => {
                                            widget.id = HelperFunctions.randomString(6);
                                        });
                                        patchState(store, setAllEntities(widgetsData.list));
                                        patchState(store, { rates: widgetsData.rates, isLoaded: true });
                                        patchState(environmentStore, {
                                            partner: widgetsData.partner,
                                            subscription: widgetsData.subscription
                                        })
                                    }
                                })
                            );
                        })
                    )
                })
            )
        }))
    )
}
