import { signalStore, withComputed, withState } from "@ngrx/signals";
import { withEntities } from "@ngrx/signals/entities";
import { AccountRates, Widget } from "./widgets.types";
import { withWidgetsApi } from "./with-widgets-api";
import { computed } from "@angular/core";

type WidgetsState = {
    isLoaded: boolean,
    rates: AccountRates
}

const initialWidgetsState: WidgetsState = {
    isLoaded: false,
    rates: {
        junior: {
            name: "junior",
            display_name: "",
            description: null,
            demo_days: 0
        },
        middle: {
            name: "middle",
            display_name: "",
            description: null,
            demo_days: 0
        },
        senior: {
            name: "senior",
            display_name: "",
            description: null,
            demo_days: 0
        }
    }
}

export const WidgetsStore = signalStore(
{providedIn: 'root', protectedState: false},
    withState(initialWidgetsState),
    withEntities<Widget>(),
    withWidgetsApi(),
    withComputed((store) => ({
        installedWidgets: computed(() => {
            return store.entities().filter((widget: Widget) => widget.installed)
        }),
        freeWidgets: computed(() => {
            return store.entities().filter((widget: Widget) => widget.rate === 'junior');
        }),
        premiumWidgets: computed(() => {
            return store.entities().filter((widget: Widget) => widget.rate === 'middle');
        }),
    }))
)
