import { cloneDeep } from 'lodash';

export abstract class HelperFunctions {
    public static randomString(length: number): string {
        let result: any[] = [];
        let characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength: number = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
        }
        return result.join('');
    }
    public static cloneDeep<T>(object: T): T {
        if (!!structuredClone) {
            return structuredClone(object);
        }
        return cloneDeep(object);
    }

    public static extractDomain(url: string): string {
        return url.replace('https://', '').split('/')[0];
    }
}
